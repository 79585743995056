import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SlackBotService } from './slack-bot.service';
import { APIResult } from '../models/APIResult.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly apiBaseUrl: string;

  // TODO: Maak hier een class van
  private errorMessagesMap: { [key: number]: string } = {
    0: '0: Kan geen verbinding maken met de server.',
    404: '404: De gevraagde bron kon niet worden gevonden.',
    // Voeg hier meer statuscodes en foutmeldingen toe indien nodig
  };

  constructor(
    private http: HttpClient,
    private slackBotService: SlackBotService,
    private userService: UserService
  ) {
    this.apiBaseUrl = environment.apiUrl;
  }

  public async getAll<T>(endpoint: string): Promise<T[]> {
    const url = this.buildUrl(endpoint);
    const token = this.getJWTTokenFromStorage();
    let currentUserId = 99999;
    if (await this.userService.getActiveUserId()) {
      currentUserId = await this.userService.getActiveUserId();
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = {
      Authorization: `Bearer ${token}`,
      UserId: `${currentUserId}`,
    };
    return (
      await this.handleRequest<APIResult<T>>(
        this.http.get<APIResult<T>>(url, { headers })
      )
    ).data;
  }

  public async getById<T>(endpoint: string, id: number): Promise<T> {
    const url = this.buildUrl(`${endpoint}/${id}`);
    const token = this.getJWTTokenFromStorage();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = { Authorization: `Bearer ${token}` };
    const result = await this.handleRequest<APIResult<T>>(
      this.http.get<APIResult<T>>(url, { headers })
    );
    return result.data[0];
  }

  public async post<T>(endpoint: string, data: any): Promise<T[]> {
    const url = this.buildUrl(endpoint);
    const token = this.getJWTTokenFromStorage();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = { Authorization: `Bearer ${token}` };
    return (
      await this.handleRequest<APIResult<T>>(
        this.http.post<APIResult<T>>(url, data, { headers })
      )
    ).data;
  }

  public logError(error: any): void {
    try {
      this.slackBotService.sendError('APP [APIService]', JSON.stringify(error));
    } catch (e) {
      console.error('APP [ApiService] logError() error: ', e);
    }
  }

  private getJWTTokenFromStorage() {
    // TODO: Obviously... fix this!
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiMTMzNyBINHhvcnogUm95In0.VG577cInUtYexhB28-zaXFCjUdhH92I30I9HuHF7gOo';
  }

  private buildUrl(endpoint: string): string {
    return `${this.apiBaseUrl}/${endpoint}`;
  }

  private async handleRequest<T>(request: Observable<T>): Promise<T> {
    try {
      return await request.toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        const status = error.status;
        const errorMessage =
          this.errorMessagesMap[status] ||
          'Er is een onverwachte fout opgetreden.';

        console.error('[ApiService] handleRequest failed:', errorMessage);
        this.logError(error);
      }

      throw new Error('An error occurred while communicating with the API.');
    }
  }

  public async getSpecific<T>(endpoint: string, start: number, amount: number) {
    const url =
      this.buildUrl(endpoint) + '?start=' + start + '&amount=' + amount;
    // console.log('APP [ApiService] getSpecific() - url: ' + url);
    const token = this.getJWTTokenFromStorage();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = { Authorization: `Bearer ${token}` };
    return (
      await this.handleRequest<APIResult<T>>(
        this.http.get<APIResult<T>>(url, { headers })
      )
    ).data;
  }
}
