import { FormsModule } from '@angular/forms';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage-angular';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { Network } from '@ionic-native/network/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FullscreenImageModalComponent } from './logboek/fullscreen-image-modal/fullscreen-image-modal.component';
import * as Sentry from '@sentry/angular-ivy';
import { TraceService } from '@sentry/angular-ivy';
import { PinchZoomModule } from '@mapaxe/ngx-pinch-zoom';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent, FullscreenImageModalComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    IonicStorageModule.forRoot(),
    FormsModule,
    PinchZoomModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    Network,
    File,
    FileOpener,
  ],
  bootstrap: [AppComponent],
  exports: [FormsModule, HttpClientModule, FullscreenImageModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
