import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from "./user.service";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: 'root',
})

/**
 * Service for sending messages and error reports to Slack.
 */
export class SlackBotService {
  private slackWebhookUrl =
    'https://hooks.slack.com/services/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';

  private httpOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/pdf',
    }),
    responseType: 'blob' as 'json',
  };

  constructor(private http: HttpClient, private userService: UserService) {}

  /**
   * Sends an error report to Slack.
   *
   * @param message The error message.
   * @param json The error details in JSON format.
   */
  public async sendError(message: string, json: string): Promise<void> {
    if(environment.appEnvironment !== 'test') {
      return;
    }
    const currentDate = new Date();
    const formattedDateTime = currentDate.toLocaleString('nl-NL');

    // Get the activeUserId
    let currentUserId: number = 99999;
    if (await this.userService.getActiveUserId()) {
      currentUserId = await this.userService.getActiveUserId();
    }

    let currentVersion: string = 'unknown';
    currentVersion = environment.appEnvironment + '_' + environment.appVersion;

    const deviceInfo = navigator.userAgent;

    const jsonObj = JSON.parse(json);

    const prettyJson = JSON.stringify(jsonObj, null, 2);

    console.log('APP [SlackBotService] sendError() - json: ' + json);
    console.log('APP [SlackBotService] sendError() - prettyJson: ' + prettyJson);

    const errorTemplate = {
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: ':rotating_light:  Error Report',
          },
        },
        {
          type: 'context',
          elements: [
            {
              type: 'mrkdwn',
              text: `<!channel> | Showlogger | *${formattedDateTime}*`,
            },
          ],
        },
        {
          type: 'divider',
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*UserId: ${currentUserId} - AppVersion: ${currentVersion}*`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*${message}*`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: '```' + prettyJson + '```',
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*Device Info:*\n${deviceInfo}`,
          },
        },
      ],
    };

    this.postMessageToSlack(JSON.stringify(errorTemplate));
  }

  /**
   * Sends a message to Slack.
   *
   * @param message The message to send.
   */
  public sendMessage(message: string): void {
    console.log('[SlackBot] Send message: ' + message);

    const payload = {
      text: message,
    };

    const payloadJSON = JSON.stringify(payload);

    console.log('Sending message to SlackBot');

    this.http.post(this.slackWebhookUrl, payloadJSON).subscribe(
      () => {
        console.log('Message sent successfully');
      },
      (error) => {
        console.error('Error sending message:', error);
      }
    );
  }

  /**
   * Posts a message to Slack using the Slack webhook URL.
   *
   * @param payload The message payload.
   */
  private postMessageToSlack(payload: any): void {
    this.http.post(this.slackWebhookUrl, payload, this.httpOptions).subscribe(
      () => {
        console.log('Message sent successfully');
      },
      (error) => {
        console.error('Error sending message:', error);
      }
    );
    // this.http
    //   .post(this.slackWebhookUrl, payload)
    //   .pipe(
    //     catchError((error: any) => {
    //       console.error(
    //         '[SlackBotService] postMessageToSlack() - Error sending message:',
    //         error
    //       );
    //
    //       if (error && error.error === 'ok') {
    //         console.log('Message sent successfully');
    //       } else {
    //         console.error(
    //           '[SlackBotService] postMessageToSlack - Failed to send message to Slack.'
    //         );
    //       }
    //
    //       return throwError(
    //         '[SlackBotService] postMessageToSlack - Failed to send message to Slack.'
    //       );
    //     })
    //   )
    //   .subscribe(() => {
    //     console.log('Message sent successfully');
    //   });
  }
}
