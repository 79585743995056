/* eslint-disable no-underscore-dangle */
import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import {
  StorageService
} from '../../services/storage.service';

const TOKEN_KEY = 'caro-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = '';
  _user = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.checkVersion();
    this.loadToken().then(() => {});
  }

  get userId() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      })
    );
  }

  async loadToken() {
    const token = await this.storageService.getLocalData(TOKEN_KEY);
    if (token) {
      this.token = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { username; password }): Observable<any> {
    console.log('[AuthService] login() invoked');
    // TODO: Replace the URL when the new API is available
    return this.http
      .post('https://royschrauwen.nl/apitest/login', credentials)
      .pipe(
        tap((data: any) => {
          console.log('[AuthService] data received: ', data);
          console.log('APP [AuthService] () - data.userId:' + data.idUser);
          if(data.idUser) {
            this.storageService.saveLocalData('userId', Number(data.idUser));
          } else {
            console.warn('APP [AuthService] () - data.idUser is undefined or null. This should not happen!');
          }
        }),
        map((data: any) => data.token),
        switchMap((token) => (this.token = token)),
        tap((_) => {
          console.log('[AuthService] token received: ', this.token);
          this.storageService.saveLocalData(TOKEN_KEY, this.token);

          this.isAuthenticated.next(true);
        })
      );
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.storageService.clearLocalData(TOKEN_KEY);
    return;
  }

  public getUserIsAuthenticated() {
    return this.isAuthenticated;
  }

  private checkVersion(): void {
  }
}
