import { inject, Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Subject } from 'rxjs';
import { FeatureFlag } from './feature-flag';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public featureFlags: Subject<FeatureFlag[]> = new Subject<FeatureFlag[]>();
  public passiveFeatureFlags: FeatureFlag[] = [];
  private api: ApiService = inject(ApiService);

  constructor() {}

  public async getAll(): Promise<FeatureFlag[]> {
    const allFeatureFlags = await this.api.getAll<any>(`featureflags`);
    this.featureFlags.next(allFeatureFlags);
    this.passiveFeatureFlags = allFeatureFlags;
    return allFeatureFlags;
  }

  public async isFeatureEnabled(feature: any) {
    if(this.passiveFeatureFlags.length === 0) {
      await this.getAll();
    }
    // console.log('APP [FeatureFlagService] isFeatureEnabled() - feature: ', feature);
    // Check in the featureFlags array if the feature has true for its active property
    // If it does, return true, otherwise return false
    const featureFlagFound = this.passiveFeatureFlags.find((ff) => ff.name === feature);
    // console.log('APP [FeatureFlagService] isFeatureEnabled() - featureFlagFound: ', featureFlagFound);
    if (featureFlagFound) {
      return featureFlagFound.active;
    } else {
      return false;
    }
  }
}
