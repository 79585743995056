import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from '../guards/intro.guard';
import { AutoLoginGuard } from '../guards/auto-login.guard';
import { AuthGuard } from './auth/auth.guard';
import { environment } from '../environments/environment';

const pathOnBoot = 'dashboard';

const routes: Routes = [
  {
    path: '',
    redirectTo: pathOnBoot,
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canLoad: [AuthGuard, IntroGuard],
  },
  {
    path: 'logboek',
    loadChildren: () =>
      import('./logboek/logboek-routing.module').then(
        (m) => m.LogboekRoutingModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthPageModule),
    canLoad: [AutoLoginGuard],
  },
  {
    path: 'showreport',
    loadChildren: () =>
      import('./showreport/showreport-routing.module').then(
        (m) => m.ShowreportRoutingModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('./tasks/tasks.module').then((m) => m.TasksPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'intro',
    loadChildren: () =>
      import('./intro/intro.module').then((m) => m.IntroPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'bestellijst',
    loadChildren: () =>
      import('./orders/bestellijst/bestellijst.module').then(
        (m) => m.BestellijstPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders/orders.module').then((m) => m.OrdersPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'ontruiming',
    loadChildren: () =>
      import('./ontruiming/ontruiming.module').then(
        (m) => m.OntruimingPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'changementen',
    loadChildren: () =>
      import('./changementen/changementsnlijst.module').then(
        (m) => m.ChangementsnlijstPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'showstop',
    loadChildren: () =>
      import('./showstop/showstop.module').then((m) => m.ShowstopPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./changelog/changelog.module').then((m) => m.ChangelogPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'showtimer',
    loadChildren: () =>
        import('./showtimer/showtimer.module').then((m) => m.ShowTimerPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'opstartprocedure',
    loadChildren: () => import('./opstartprocedure/opstartprocedure.module').then( m => m.OpstartprocedurePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./maintenance/maintenance.module').then((m) => m.MaintenancePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'protocols',
    loadChildren: () => import('./protocols/protocols.module').then(m => m.ProtocolsPageModule),
    canLoad: [AuthGuard],
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
    console.log(
      '[AppRoutingModule] appVersion: ',
      environment.appEnvironment + '-' + environment.appVersion
    );
  }
}
