import 'zone.js/dist/zone-error'; // Included with Angular CLI.

export const environment = {
  apiUrl: 'https://api.showreport.nl/api/v1',
  appEnvironment: 'test',
  appVersion: '2.10.0',
  production: false,
  appName: 'CARO Logboek TEST',
  // iosTeamId: '',
  iosAppBundleId: 'com.softalist.carologboek',
  androidAppBundleId: 'com.softalist.carologboek',
};
