export class User {

  public id: number;
  public firstName = '';
  public lastName = '';
  public username = '';
  public voornaam = '';
  public achternaam = '';
  public email = '';
  public rolId = 0;
  public status = '';


  public static create(
    id: number,
    username: string,
    voornaam: string,
    achternaam: string,
    email: string,
    rolId: number
  ): User {
    const instance = new User();
    instance.id = id;
    instance.username = username;
    instance.voornaam = voornaam;
    instance.achternaam = achternaam;
    instance.email = email;
    instance.rolId = rolId;
    return instance;
  }

  /**
   * Creates a User from a json object
   *
   * @param json: any | User
   * @returns User
   */
  public static createFromJson(json: any | User): User {
    const user = new User();
    user.id = json.id;
    user.username = json.username;
    user.voornaam = json.voornaam;
    user.achternaam = json.achternaam;
    user.email = json.email;
    user.rolId = json.rolId;
    return user;
  }

  public static fromJson(json: any): User {
    const instance = new User();
    instance.id = json.id;
    instance.username = json.username;
    instance.voornaam = json.voornaam;
    instance.achternaam = json.achternaam;
    instance.email = json.email;
    instance.rolId = json.rolId;
    return instance;
  }

  public toJson(): any {
    return {
      id: this.id,
      username: this.username,
      voornaam: this.voornaam,
      achternaam: this.achternaam,
      email: this.email,
      rolId: this.rolId
    };
  }

  public getId(): number {
    return this.id;
  }

  public setUsername(username: string) {
    this.username = username;
  }

  public getVoornaam(): string {
    return this.voornaam;
  }

  public getAchternaam(): string {
    return this.achternaam;
  }

  public setVoornaam(voornaam: string): void {
    this.voornaam = voornaam;
  }

  public setAchternaam(achternaam: string): void {
    this.achternaam = achternaam;
  }

  public getVolledigeNaam(): string {
    return this.voornaam + ' ' + this.achternaam;
  }

  public getUsername(): string {
    return this.username;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string): void {
    this.email = email;
  }

  public getRolId(): number {
    return this.rolId;
  }

  public setRolId(rolId: number): void {
    this.rolId = rolId;
  }
}
